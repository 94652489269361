<template>
  <div class="container">
    <div class="row align-items-center lg-center my-lg-5">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5 mx-auto my-5">
        <div class="card p-4 shadow-lg border-0 rounded mx-2">
          <div class="mt-3">
            <h2 class="text-center text-info fw-semibold">Reset Password</h2>
          </div>
          <form>
            <div class="my-4">
              <label for="exampleFormControlInput1" class="form-label"
                >Password <span class="required">*</span></label
              >
              <input
                type="password"
                class="form-control"
                :class="{
                  'is-invalid': $v.formFields.password.password.$error,
                }"
                id="exampleFormControlInput1"
                v-model="formFields.password.password"
                placeholder="Enter Password"
              />
            </div>
            <div class="my-4">
              <label for="exampleFormControlInput1" class="form-label"
                >Confirm Password <span class="required">*</span></label
              >
              <input
                type="password"
                class="form-control"
                :class="{
                  'is-invalid': $v.formFields.password.confirmPassword.$error,
                }"
                id="exampleFormControlInput1"
                v-model="formFields.password.confirmPassword"
                placeholder="Enter Confirm Password"
              />
            </div>
            <div class="mb-4 mt-2 d-flex">
              <button
                class="btn btn-primary text-white rounded-pill text-uppercase fw-bold px-4 py-2"
                :class="loading ? 'disabled' : ''"
                type="submit"
                @click="submitForm"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  name: "ResetPassword",
  data() {
    return {
      loading: false,
      password_token: "",
      formFields: {
        password: {
          password: "",
          confirmPassword: "",
        },
      },
    };
  },
  validations: {
    formFields: {
      password: {
        password: { required, minLength: 6 },
        confirmPassword: {
          required,
          minLength: 6,
          sameAsPassword: sameAs(function () {
            return this.formFields.password.password;
          }),
        },
      },
    },
  },
  created() {
    if (this.$route.query.token) {
      this.password_token = this.$route.query.token;
    } else {
      this.$router.push("/login");
    }
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      // console.log(this.$v.formFields.password.confirmPassword)
      let obj = {
        token: this.password_token,
        password: this.formFields.password.password,
      };
      //  console.log( this.userForm,this.$v.$invalid)
      if (!this.$v.$invalid) {
        this.loading = true;
        axios
          .post(
            process.env.VUE_APP_BASEURL + "/resetPasswordAfterVerifyOtp",
            obj,
            {
              headers: {
                "Content-type": "application/json",
              },
            }
          )
          .then((data) => {
            this.loading = false;
            this.$toasted.show(`password reset successfully `, {
              theme: "bubble",
              type: "success",
              position: "top-center",
              duration: 8000,
              singleton: true,
            });
            this.$router.push("/login");
          })
          .catch((err) => {
            // console.log(err)
            this.loading = false;
            this.$toasted.show(`Something Went wrong `, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 8000,
              singleton: true,
            });
          });
      } else {
        // alert("form submission failed");
      }
    },
  },
};
</script>

<style></style>
