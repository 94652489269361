import Vue from 'vue'
import Vuex from 'vuex'
import feedsModules from '../store/modules/feedsModule'
import usersModules from '../store/modules/usersModule'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    feedsModules: feedsModules,
    usersModules: usersModules
  }
})
