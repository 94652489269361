<template>
  <div class="external-videos">
    <LazyYoutube
      class="video"
      :src="youtubeLink"
      max-width="720px"
      aspect-ratio="16:9"
      thumbnail-quality="standard"
    />
  </div>
</template>

<script>
import { LazyYoutube, LazyVimeo } from "vue-lazytube";
export default {
  name: "Video",
  components: {
    LazyYoutube,
  },
  props: {
    feed: {
      type: Object,
    },
  },
  data() {
    return {
      youtubeLink: "",
      vimeoLink: "",
    };
  },
  created() {
    this.youtubeLink = this.feed.media?.url;
    this.vimeoLink = this.feed.media?.url;
  },
  watch: {
    feed: function (newFeed, oldFeed) {
      this.youtubeLink = newFeed.media?.url;
    },
  },
};
</script>

<style></style>
