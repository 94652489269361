<template>
  <div class="container">
    <div class="row align-items-center lg-center">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5 mx-auto my-5">
        <div
          class="card p-4 shadow-lg border-0 rounded mx-2"
          style="z-index: 1"
        >
          <div class="mt-3">
            <h2 class="text-center text-info fw-semibold">Login</h2>
          </div>
          <form @submit.prevent="submitForm" class="m-4">
            <div class="mb-3">
              <label for="email" class="form-label"
                >Email <span class="required">*</span></label
              >
              <input
                type="email"
                class="form-control"
                :class="{ 'is-invalid': $v.userForm.email.$error }"
                id="email"
                v-model="userForm.email"
                placeholder="Enter Email"
                autocomplete="username"
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label"
                >Password <span class="required">*</span></label
              >
              <input
                type="password"
                class="form-control"
                :class="{ 'is-invalid': $v.userForm.password.$error }"
                id="password"
                v-model="userForm.password"
                autocomplete="current-password"
                placeholder="Enter Password"
              />
            </div>
            <div class="mb-3 form-check d-flex justify-content-between">
              <div>
                <input
                  type="checkbox"
                  v-model="userForm.checked"
                  class="form-check-input"
                  id="remember_me"
                />
                <label class="form-check-label" for="remember_me"
                  >Remember Me</label
                >
              </div>
              <router-link
                class="text-decoration-none text-info"
                to="/forget-password"
                >Forgot Password ?</router-link
              >
            </div>
            <div class="d-flex justify-content-between">
              <button
                type="submit"
                class="btn btn-primary text-white rounded-pill text-uppercase fw-bold px-5 py-2"
                :class="loading ? 'disabled' : ''"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Login
              </button>
              <div class="m-2"></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { reactive, computed } from "vue";
export default {
  name: "Login",
  components: {},
  watch: {
    checked: function (val, oldVal) {
      console.log(val, oldVal);
    },
  },

  data() {
    return {
      obj: {},
      value: "",
      loading: false,
      userForm: {
        email: "",
        password: "",
        checked: "",
      },
    };
  },
  validations: {
    userForm: {
      email: { required, email },
      password: { required },
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      this.$router.push("/dashboard");
    } else {
      let obj = JSON.parse(localStorage.getItem("obj"));
      this.obj = obj;
      if (obj) {
        this.userForm = obj;
      }
    }
  },
  methods: {
    postNow() {
      this.loading = true;
      var obj = {
        email: this.userForm.email,
        password: this.userForm.password,
      };
      this.value = this.userForm.email;
      axios
        .post(process.env.VUE_APP_BASEURL + "/login", obj, {
          headers: {
            "Content-type": "application/json",
          },
        })
        .then((res) => {
          localStorage.setItem("pay_email", this.userForm.email);
          if (res?.data?.token) {
            if (this.userForm.checked) {
              localStorage.setItem("obj", JSON.stringify(this.userForm));
            } else {
              localStorage.removeItem("obj");
            }

            if (res?.data?.user?.role == "User") {
              if (res?.data?.pay_status == 0) {
                this.loading = true;
                localStorage.setItem("token", res?.data?.token);
                localStorage.setItem("role", res?.data?.user?.role);
                localStorage.setItem("pay_status", res?.data?.pay_status);
                localStorage.setItem("id", res?.data?.user?.id);
                localStorage.setItem("name", res.data?.user?.first_name);
                localStorage.setItem("used", res?.data?.user?.trail_used);
                this.$router.push("/payments/due");
              } else {
                localStorage.setItem("token", res?.data?.token);
                localStorage.setItem("pay_status", res?.data?.pay_status);
                localStorage.setItem("role", res?.data?.user?.role);
                localStorage.setItem("id", res?.data?.user?.id);
                localStorage.setItem("name", res.data?.user?.first_name);
                localStorage.setItem("used", res?.data?.user?.trail_used);
                this.$router.push("/dashboard");
              }
            } else if (res?.data?.user?.role == "Admin") {
              this.loading = true;
              localStorage.setItem("token", res?.data?.token);
              localStorage.setItem("role", res?.data?.user?.role);
              localStorage.setItem("name", res.data?.user?.first_name);
              localStorage.setItem("used", res?.data?.user?.trail_used);

              this.$router.push("/admin/users");
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.$toasted.show(` Invalid Credentials`, {
            theme: "bubble",
            type: "error",
            position: "top-center",
            duration: 1500,
            singleton: true,
          });
          this.loading = false;
        });
    },
    resetForm() {
      var self = this;
      Object.keys(this.userForm).forEach(function (key, index) {
        self.userForm[key] = "";
      });
    },
    submitForm(e) {
      e.preventDefault();

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.postNow();
      } else {
      }
    },
  },
};
</script>

<style>
.required {
  color: red;
}
</style>
