<template>
  <div class="footer bg-bottom" style="font-family: Montserrat, sans-serif">
    <div class="text-white pb-3 pt-4">
      <div
        class="custom-container fw-semibold d-flex justify-content-md-between align-items-center"
      >
        <p class="mb-0">
          Copyright ({{ currentYear }}) Social Arrow. All rights reserved.
        </p>
        <div class="terms">
          <a
            class="text-white text-decoration-none pe-2"
            target="_blank"
            :href="privacyPolicyUrl"
            >Privacy Policy</a
          >
          |
          <a
            class="text-white text-decoration-none ps-2"
            target="_blank"
            :href="termsConsitionsUrl"
            >Term of use</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      userLoggedIn: false,
      currentYear: new Date().getFullYear(),
      privacyPolicyUrl:
        process.env.VUE_APP_PROMOTIONAL_PAGE_URL +
        "Privacy-Policy/a~475/article.html",
      termsConsitionsUrl:
        process.env.VUE_APP_PROMOTIONAL_PAGE_URL +
        "Terms-of-use/a~28/article.html",
    };
  },
  mounted() {
    if (localStorage.token) {
      this.userLoggedIn = true;
    } else {
      this.userLoggedIn = false;
    }
  },
};
</script>

<style>
.bg-bottom {
  background-color: #023f77;
}
.fw-semibold {
  font-weight: 600 !important;
}
.custom-container {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
</style>
