<template>
  <div class="py-5">
    <div class="col-11 col-md-5 mx-auto forget mt-1 card p-5 shadow-lg border rounded">
      <div class="">
        <h3 class="text-center">Verify OTP</h3>
      </div>
      <form @submit.prevent="submitForm" class="p-3">
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >Email <span class="required">*</span></label
          >
          <input
            readonly
            type="email"
            class="form-control cursor-pointer"
            id="exampleFormControlInput1"
            v-model="userForm.email"
            placeholder="Enter Email"
          />
        </div>
        <div class="mb-3">
          <label for="exampleFormControlInput1" class="form-label"
            >OTP <span class="required">*</span></label
          >
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.userForm.otp.$error }"
            id="exampleFormControlInput1"
            v-model="userForm.otp"
            placeholder="Enter otp"
          />
        </div>
        <div class="mb-3 mt-3">
          <button
            class="btn btn-primary text-white rounded-pill text-uppercase fw-bold px-4 py-2"
            type="button"
            @click="submitForm"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Verify
          </button>
        </div>
      </form>
      <!-- <div v-for="item in feedsList" :key="item.id">
     {{item.name}}
    </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "VerifyOtp",
  data() {
    return {
      loading: false,

      userForm: {
        email: "",
        otp: "",
      },
    };
  },
  validations: {
    userForm: {
      otp: { required },
      email: { required, email },
    },
  },
  created() {
    if (this.$route.query.email) {
      this.userForm.email = this.$route.query.email;
    } else {
      this.$router.push("/login");
    }
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      this.loading = true;
      this.$v.$touch();
      let obj = {
        email: this.userForm.email,
        otp: this.userForm.otp,
      };
      if (!this.$v.$invalid) {
        axios
          .post(process.env.VUE_APP_BASEURL + "/resetPasswordVerifyOtp", obj, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            this.loading = false;
            // this.$toasted.show(
            //   `Reset link send to your otp ,please check... `,
            //   {
            //     theme: "bubble",
            //     type: "success",
            //     position: "top-center",
            //     duration: 1000,
            //     singleton: true,
            //   }
            // );
            this.$router.push({
              path: "/reset-password",
              query: { token: response.data.token },
            });
          })
          .catch((err) => {
            // console.log(err)
            this.loading = false;
            this.$toasted.show(`You entered wrong OTP `, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 8000,
              singleton: true,
            });
          });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
