<template>
  <div class="container">
    <div class="row align-items-center lg-center my-lg-5">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5 mx-auto my-5">
        <div class="card p-4 shadow-lg border-0 rounded mx-2">
          <div class="mt-3">
            <h2 class="text-center text-info fw-semibold">Forgot Password</h2>
          </div>
          <form>
            <div class="my-4">
              <label for="exampleFormControlInput1" class="form-label"
                >Email <span class="required">*</span></label
              >
              <input
                type="email"
                class="form-control"
                :class="{ 'is-invalid': $v.userForm.email.$error }"
                id="exampleFormControlInput1"
                v-model="userForm.email"
                placeholder="Enter Email"
              />
            </div>
            <div class="mb-4 mt-2 d-flex">
              <button
                class="btn btn-primary text-white rounded-pill text-uppercase fw-bold px-4 py-2"
                :class="loading ? 'disabled' : ''"
                type="submit"
                @click="submitForm"
              >
                <span
                  v-if="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Send
              </button>
              <router-link
                @click.native="$scrollToTop"
                to="/login"
                class="text-dark"
                style="text-decoration: none"
              >
                <button
                  class="btn btn-info text-white rounded-pill text-uppercase fw-bold ms-2 px-4 py-2"
                >
                  Back To Login
                </button>
              </router-link>
            </div>
          </form>
          <!-- <div v-for="item in feedsList" :key="item.id">
     {{item.name}}
    </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  name: "ForgetPassword",
  data() {
    return {
      loading: false,
      userForm: {
        email: "",
      },
    };
  },
  validations: {
    userForm: {
      email: { required, email },
    },
  },

  methods: {
    submitForm(e) {
      e.preventDefault();
      this.loading = true;
      this.$v.$touch();
      let obj = {
        email: this.userForm.email,
      };
      if (!this.$v.$invalid) {
        axios
          .post(process.env.VUE_APP_BASEURL + "/myresetPassword", obj, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((data) => {
            this.loading = false;
            this.$toasted.show(
              `OTP has been sent at your email, please check`,
              {
                theme: "bubble",
                type: "success",
                position: "top-center",
                duration: 8000,
                singleton: true,
              }
            );
            //console.log(this.userForm.email)
            this.$router.push({
              path: "/verify-otp",
              query: { email: this.userForm.email },
            });
          })
          .catch((err) => {
            // console.log(err)
            this.loading = false;
            this.$toasted.show(`user does not exists `, {
              theme: "bubble",
              type: "error",
              position: "top-center",
              duration: 8000,
              singleton: true,
            });
          });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.forget {
  margin-top: 190px;
}
</style>
