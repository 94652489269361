<template>
  <div
    ref="anyName"
    class="col-6 mx-auto mt-5 blog-post-creator card p-5 shadow-lg border rounded blog-post-creator"
  >
    <h4 class="m-2" style="text-align: center">Edit Feed</h4>
    <form class="m-3  p-3">
      <div class="">
        <div class="title-input">
          <input
            class="form-control"
            v-model="forms.title"
            placeholder="Enter a post title"
            :class="{ 'is-invalid': $v.forms.title.$error }"
          />
          <p v-if="$v.forms.title.$error" class="text-left pt-1" style="color: red">
            Post must have a title
          </p>
        </div>
        <!-- <div class="mb-3 ">  
        <v-select
		:class="{ 'is-invalid1': $v.forms.category.$error }"
          label="name"
          v-model="forms.category"
          placeholder="select category"
          :options=" typeOptions"
        ></v-select>
      
			</div> -->
        <div class="select-media-type mt-2 ms-1 py-2">
          <label>Select Media Type</label>
          <div class="d-flex gap-3 align-items-center">
            <div class="media-image d-flex gap-2 align-items-center" role="button" >
              <input type="radio" role="button"  name="select-media" id="select-image" value="image" :checked="forms.mediaType == 'image'" @change="changeMediaType">
              <label for="select-image" role="button" > Image </label>
            </div>
            <div class="media-video d-flex gap-2 align-items-center" role="button" >
              <input type="radio" role="button"  name="select-media" id="select-video" value="video" :checked="forms.mediaType == 'video'" @change="changeMediaType">
              <label for="select-video" role="button" > Video </label>
            </div>             
             <div class="media-video d-flex gap-2 align-items-center" role="button" >
                <input type="radio" role="button"  name="select-media" id="select-url" value="url" :checked="forms.mediaType == 'url'" @change="changeMediaType">
                <label for="select-url" role="button" >Embed Url </label>
              </div> 
          </div>
           <p
                v-if="$v.forms.mediaType.$error"
                class="text-left"
                style="color: red"
              >
                Please select type of media
          </p>   
        </div>
        <div
          class="inner-image-input py-2 gap-2 d-flex"
          v-if="forms.mediaType == 'image'"
        >
          <div class="image-input">
            <label
              class="ivu-btn ivu-btn-primary"
              for="postMedia"
              icon="ios-camera-outline"
              >Add Image
              <input
                type="file"
                id="postMedia"
                accept="image/*"
                @change="uploadMedia"
                style="display: none"
              />
            </label>
            <div v-if="image_url == ''" class="mt-1">
              <small class="text-danger font-monospace"
                >1. Max upload size: 5 mb</small
              >
            </div>
            <div v-if="image_url== ''" class="mt-1">
                <small class="text-danger font-monospace">2. Accepted Extensions (jpg or jpeg, png)</small> 
              </div>    
            <p v-if="$v.forms.file.$error" class="text-left" style="color: red">
              Post must have an image
            </p>
          </div>
          <div class="preview">
            <img
              :src="image_url"
              width="40px"
              class="mx-2"
              height="40px"
              v-if="image_url"
            />
          </div>
        </div>
        <div class="inner-video-input py-2 gap-2 d-flex" v-if="forms.mediaType == 'video'">  
            <div class="video-input">
              <label
                class="ivu-btn ivu-btn-primary"
                for="postMedia"
                icon="ios-camera-outline"
                >Add Video
                <input
                  type="file"
                  id="postMedia"
                  accept="video/*"
                  @change="uploadMedia"
                  style="display: none"
                />
              </label>   
              <div v-if="mediaSrc == ''" class="mt-1">
                 <small class="text-danger font-monospace">1. Max upload size: 50 mb</small> 
              </div>  
              <div v-if="mediaSrc== ''" class="mt-1">
                <small class="text-danger font-monospace">2. Accepted Extensions (mov, mp4)</small> 
              </div>  
              <p
                v-if="$v.forms.media.$error"
                class="text-left"
                style="color: red"
              >
                Post must have an video
              </p>
            </div> 
            <div class="video-video-preview">
              <video width="200" controls v-if="mediaSrc">
                <source :src="mediaSrc" id="media_preview">
                  Your browser does not support HTML5 video.
              </video>
            </div>    
          </div>  

           <div class="mb-2" v-if="forms.mediaType =='url'">
             <label
                class="w-100"
                for="postMedia"
                icon="ios-camera-outline"
                >
                <input
                  type="url"
                  placeholder="Embed url"
                  class="form-control"
                  :class="{ 'is-invalid': (!isValid && embed_url!='') || $v.forms.embed_url.$error }"
                 v-model="embed_url"
                  @input="uploadMedia"
                
                />
                 <p v-if="!isValid && embed_url!=''" class="mt-1 text-danger">Please enter a valid url.</p>
                <p
                v-if="$v.forms.embed_url.$error"
                class="mt-1 text-left"
                style="color: red"
              >
                Post must have an Embed video Url
              </p> 
              </label>  
                <div v-if="forms.mediaType== 'url'" class="mt-1">
                <small class="text-danger font-monospace"
                  >1. URL with extension mp4, mov, jpg or jpeg, png will be embedded on social media. For other type of URL, it will be attached to the post as a link.</small
                >
                </div>
                  <div v-if="forms.mediaType== 'url'">
                <small class="text-danger font-monospace"
                  >2. For Image URL, size of the file should not be more than 5 MB
                </small>
                  </div>
                  <div v-if="forms.mediaType== 'url'">
                 <small class="text-danger font-monospace"
                  >3. For video URL,  size of the file should not be more than 50 MB
                </small>
                    
              </div>
          </div> 
      </div>
      <!-- <div class="mb-3">
  <label for="exampleFormControl Textarea1" class="form-label">Content</label>
  <textarea class="form-control" 
  :class="{ 'is-invalid': $v.forms.content.$error }"
  v-model="forms.content" id="exampleFormControlTextarea1" rows="3"></textarea>
</div> -->
      <div class="mb-2 mt-3">
        <quill-editor
          v-model="forms.content"
          ref="myQuillEditor"
          @change="onEditorChange($event)"
           :class="{ 'is-invalid': $v.forms.content.$error }"
          :options="editorOption"
        />
        <p v-if="$v.forms.content.$error" class="text-left" style="color: red">
          Post must have a description
        </p>
      </div>
      <!-- <div class="mb-3">
    <date-picker v-model="time1"  placeholder="select date" format="YYYY-MM-DD" valuetype="format" lang="jp"></date-picker>
  </div> -->
      <div class="mt-3">
        <button class="ivu-btn ivu-btn-primary" @click="save">
          Save
          <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
        <button class="ivu-btn ivu-btn-secondary mx-2" @click="Cancel">
          Cancel
        </button>
      </div>
    </form>
    <!-- <save-and-schedule
      :data="user"
      :feed_id="feedId"
      :load="isLoading1"
    ></save-and-schedule> -->
  </div>
</template>

<script>
import axios from "axios";
// import SaveAndSchedule from "./saveAndSchedule.vue";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import "iview/dist/styles/iview.css";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AddPost",
  components: {
    quillEditor,
    // SaveAndSchedule,
  },

  data() {
    return {
       embed_url:'',
        isValid:'',
      time1: "",
      feedId: "",
      con: "",
      user: {},
      editorOption: {
        placeholder: "Type your post...",
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction
                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],
                ['clean']
                //['link', 'image','video']                                  
          ],
          
      }
      },
      isLoading: false,
      isLoading1: false,
      forms: {
        title: "",
        file: "",
        embed_url:"",
        media: "",
        mediaType: "",
        content: "",
      },
      image_url: "",
      mediaSrc: "",
      typeOptions: [
        {
          name: "external",
          value: "external",
        },
        {
          name: "education",
          value: "education",
        },
        {
          name: "inspirational",
          value: "inspirational",
        },
        {
          name: "funny",
          value: "funny",
        },
        {
          name: "observances",
          value: "events",
        },
      ],
    };
  },

  validations() {
    if (this.forms.mediaType == "image" && this.image_url == "") {
      return {
        forms: {
          title: { required },
          file: { required },
          embed_url:{},
          media: {},
          mediaType: { required },
          content: { required },
        },
      };
    } else if (this.forms.mediaType == "video" && this.mediaSrc == "") {
      return {
        forms: {
          title: { required },
          file: {},
          embed_url:{},
          media: { required },
          mediaType: { required },
          content: { required },
        },
      };
    }else if(this.forms.mediaType =="url" && this.embed_url == ""){
       return {
        forms: {
          title: { required },
          file: {},
          embed_url:{required},
          media: {  },
          mediaType: { required },
          content: { required },
        },
      };
    } else {
      return {
        forms: {
          title: { required },
          file: {},
          embed_url:{},
          media: {},
          mediaType: { required },
          content: { required },
        },
      };
    }
  },
  created() {
    //console.log(this.$route.params.id)
    this.getFeedData(this.$route.params.id);
    this.id = this.$route.params.id;
    axios
      .get(process.env.VUE_APP_BASEURL + "/getupdateProfile", {
        headers: {
          "Content-type": "application/json",
          token: `${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        //	console.log(res.data)
        if (res.status) {
          this.user = res?.data?.user_data;
        }
      })
      .catch(() => {});
  },
  methods: {
      isURLValid(inputUrl) {
       
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      this.isValid = !!pattern.test(inputUrl);
     
    },
    getFeedData(id) {
      axios
        .get(process.env.VUE_APP_BASEURL + `/get-custom-feed?feed_id=${id}`, {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res.data)
          let ab = JSON.parse(res.data?.data?.media);
          if (res.data.status) {
            this.con = res.data?.data?.body;
            this.forms.content = res.data?.data?.body.replace(/\r?\n|\r/g, '<br>');             
            this.forms.title = res.data?.data?.title;
            this.forms.mediaType = ab.type;
            if (ab.type == "image") {
              this.image_url = ab.url;
            } else if(ab.type=='url' && ab.url!=null){
              this.embed_url=ab.url
              this.isValid = true
            }else {
              this.mediaSrc = ab.url;
            }
          }
        })
        .catch(() => {});
    },
    // saveAndSchedule(e) {
    //   e.preventDefault();

    //   this.$v.$touch();
    //   if ( this.embed_url != '' && this.isValid == false ){
    //       return false;
    //   }
    //   this.isLoading1 = true;

    //   //	console.log('sds',this.con,this.file)
    //   if (!this.$v.$invalid) {
    //     let formData = new FormData();
    //     if (this.forms.file != "") {
    //       formData.append("image", this.forms.file);
    //     }
    //     if (this.forms.media != "") {
    //       formData.append("media", this.forms.media);
    //     }
    //      if(this.embed_url!=''){
    //       formData.append("embed_url", this.embed_url);
    //     }
    //     formData.append("title", this.forms.title);
    //     formData.append("mediaType", this.forms.mediaType);
    //     formData.append("body", this.con);
    //     formData.append("feed_id", this.id);
    //     axios
    //       .post(process.env.VUE_APP_BASEURL + "/edit-custom-feed", formData, {
    //         headers: {
    //           "Content-type": "application/json",
    //           token: `${localStorage.getItem("token")}`,
    //         },
    //       })
    //       .then((res) => {
    //         //   this.forms.content='';
    //         //   this.forms.title='';
    //         //   this.forms.file='';

    //         this.image_url = "";
    //         //this.forms.category='';
    //         this.feedId = res?.data?.feed_id;
    //         $(`#saveandscheduledModal`).modal("show");
    //         this.isLoading1 = false;
    //         //this.resetForm();
    //         //  this.$v.$reset();
    //         //   this.$toasted.show(
    //         //   `Post Added Successfully `,
    //         //   {
    //         //     theme: "bubble",
    //         //     type: "success",
    //         //     position: "top-center",
    //         //     duration: 1500,
    //         //     singleton: true,
    //         //   }
    //         // );
    //       })
    //       .catch((err) => {
    //         if (err?.response?.data?.error_message != "") {
    //           var error_msg = "";
    //           if (err.response.data.error_message.image) {
    //             error_msg = err.response.data.error_message.image[0];
    //           } else if (err.response.data.error_message.media) {
    //             error_msg = err.response.data.error_message.media[0];
    //           } else if (err.response.data.error_message.title) {
    //             error_msg = err.response.data.error_message.title[0];
    //           } else if (err.response.data.error_message.body) {
    //             error_msg = err.response.data.error_message.body[0];
    //           }
    //           this.$toasted.show(error_msg, {
    //             theme: "bubble",
    //             type: "error",
    //             position: "top-center",
    //             duration: 4500,
    //             singleton: true,
    //           });
    //         } else {
    //           this.$toasted.show(`Internal Server Error `, {
    //             theme: "bubble",
    //             type: "error",
    //             position: "top-center",
    //             duration: 4500,
    //             singleton: true,
    //           });
    //         }
    //         this.isLoading1 = false;
    //       });
    //   } else {
    //     this.isLoading1 = false;
    //   }
    // },
    onEditorChange({ quill, html, text }) {
        this.con = text;
    },
    change(event) {},
    close(event) {},
    changeMediaType(event) {
      // this.mediaSrc = "";
      // this.image_url = "";
      // this.forms.media = "";
      this.$v.$reset();
      this.forms.mediaType = event.target.value;
    },
    uploadMedia(event) {
      if (this.forms.mediaType == "image") {
        this.mediaSrc = "";
        this.forms.media = "";
        this.forms.file = event.target.files[0];
        var fileReader = new FileReader();
        fileReader.onload = (ev) => {
          this.image_url = ev.target.result;
        };
        fileReader.readAsDataURL(event.target.files[0]);
      }
      else if(this.forms.mediaType == "url"){
           this.mediaSrc = "";
        this.forms.media = "";      
        this.isURLValid(this.embed_url)
        //return;
      }  else {
        this.image_url = "";
        this.forms.file = "";
        this.forms.media = event.target.files[0];
        this.mediaSrc = URL.createObjectURL(event.target.files[0]);
        setTimeout(() => {
          $("#media_preview").parent()[0].load();
        }, 300);
      }
    },
    resetForm() {
      var self = this; //you need this because *this* will refer to Object.keys below`

      //Iterate through each object field, key is name of the object field`
      Object.keys(this.forms).forEach(function (key, index) {
        self.forms[key] = "";
      });
    },
    save(e) {
      // TODO
      e.preventDefault();
      this.$v.$touch();
      if ( this.embed_url != '' && this.isValid == false ){
          return false;
      }
      //	console.log(this.forms.title,this.forms.content,this.file)
      if (!this.$v.$invalid ) {
       
        this.isLoading = true;

        let formData = new FormData();
        if (this.forms.file != "") {
          formData.append("image", this.forms.file);
        }
        if (this.forms.media != "") {
          formData.append("media", this.forms.media);
        }
         if(this.embed_url!=''){
          formData.append("embed_url", this.embed_url);
        }
        formData.append("mediaType", this.forms.mediaType);
        formData.append("feed_id", this.id);
        formData.append("title", this.forms.title);
        formData.append("body", this.con);
        axios
          .post(process.env.VUE_APP_BASEURL + "/edit-custom-feed", formData, {
            headers: {
              "Content-type": "application/json",
              token: `${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            setTimeout(() => {
              this.isLoading = false;
              this.$toasted.show(`Post Updated Successfully `, {
                theme: "bubble",
                type: "success",
                position: "top-center",
                duration: 1500,
                singleton: true,
              });
              this.$router.push("/dashboard");
            }, 500);
          })
          .catch((err) => {
            this.isLoading = false;
            if (err?.response?.data?.error_message != "") {
              var error_msg = "";
              if (err.response.data.error_message.image) {
                error_msg = err.response.data.error_message.image[0];
              } else if (err.response.data.error_message.media) {
                error_msg = err.response.data.error_message.media[0];
              } else if (err.response.data.error_message.title) {
                error_msg = err.response.data.error_message.title[0];
              } else if (err.response.data.error_message.body) {
                error_msg = err.response.data.error_message.body[0];
              }
              this.$toasted.show(error_msg, {
                theme: "bubble",
                type: "error",
                position: "top-center",
                duration: 4500,
                singleton: true,
              });
            } else {
              this.$toasted.show(`Internal Server Error `, {
                theme: "bubble",
                type: "error",
                position: "top-center",
                duration: 1500,
                singleton: true,
              });
            }
          });
      } else {
        this.isLoading = false;
      }
    },
    Cancel() {

      this.$router.push("/dashboard");
    },
  },
};
</script>

<style>
.cat-input,
.title-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.post-inputs {
  display: grid;
  width: 90%;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  padding-bottom: 15px;
  padding-top: 15px;
  /* padding: 20px; */
}
.ql-editor {
  height: 72vh;
}
.inner-image-input {
  display: flex;
  align-items: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.post-actions-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.ivu-icon-ios-camera-outline {
  font-size: 16px;
}
.btn-delete {
  color: #2d8cf0 !important;
  border: 1px solid #2d8cf0 !important;
}
.btn-delete:hover {
  color: red !important;
  border: 1px solid red !important;
}

.is-invalid1 {
  border: 1px solid red;
  border-radius: 5px;
}
.ql-editor {
  height: 30vh !important;
}
</style>
