<template>
  <div class="col-12 bg-light py-5">
    <div class="container">
      <div class="row">
        <div class="mx-auto mb-5">
          <div class="py-5 text-center">
            <img src="/images/exclamation.png" />
            <h1 class="display-2 fw-bold text-center text-info pt-5 mb-2">
              <span class="text-primary">Opps !</span> Something Went Wrong
            </h1>
            <h3 class="text-secondary fw-400">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry
            </h3>

            <div class="pt-5">
              <router-link
                @click.native="$scrollToTop"
                class="btn btn-outline-info text-uppercase rounded-pill text-uppercase fs-5 fw-semibold px-5 py-3"
                to="/"
                >Go to Home</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cancel",
  created() {
    if (!this.$route?.query?.session_id) {
      this.$router.push("/dashboard");
    } else {
      localStorage.removeItem("token");
    }
  },
};
</script>

<style>
.payment {
  border: 1px solid #f01b1b;
  height: 280px;
  border-radius: 20px;
  background: #fff;
}

.check {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  text-align: center;
}

.check i {
  vertical-align: middle;
  line-height: 50px;
  font-size: 30px;
}

.content {
  text-align: center;
}

.content h1 {
  font-size: 25px;
  padding-top: 25px;
}
</style>
