import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//Bootstrap icons/css
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css';


import 'vue-select/dist/vue-select.css';
import Vuelidate from 'vuelidate'
import VueClipboard from 'vue-clipboard2'
import VueGoodTablePlugin from 'vue-good-table';
import ToggleButton from 'vue-js-toggle-button'
import IdleVue from 'idle-vue'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
Vue.use(VueMoment, {
  moment,
})
const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 200000
})
Vue.use(ToggleButton)

// import the styles 
import 'vue-good-table/dist/vue-good-table.css'
import Toasted from 'vue-toasted';

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

Vue.use(Toasted)
Vue.use(VueGoodTablePlugin);

Vue.use(VueClipboard)
Vue.use(Vuelidate)

Vue.config.productionTip = false
import Carousel3d from 'vue-carousel-3d';
Vue.use(Carousel3d);

Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0)

new Vue({
  router,
  store,
  onIdle() {
  },
  render: h => h(App)
}).$mount('#app')

import "bootstrap/dist/js/bootstrap.js"
