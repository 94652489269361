<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand">Admin</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav user_menu">
          <!-- <li class="nav-item" >
          <router-link class="nav-link " aria-current="page" to="/admin">Dashboard</router-link>
        </li> -->
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/admin/users"
              >Users</router-link
            >
          </li>
          <li>
            <div class="nav-item dropdown">
              <a
                class=""
                type="button "
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="../../assets/b.jpg"
                  width="40"
                  height="40"
                  class="admin-brand-img rounded-circle"
                />
              </a>
              <ul
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <router-link
                    class="dropdown-item"
                    aria-current="page"
                    to="/admin/profile"
                    >My Profile</router-link
                  >
                </li>
                <li><a class="dropdown-item" @click="logout">Logout</a></li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "axios";
export default {
  name: "AdminNavbar",
  methods: {
    logout() {
      axios
        .post(process.env.VUE_APP_BASEURL + "/logout", {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        })
        .then(() => {})
        .catch(() => {});
      localStorage.removeItem("token");
      localStorage.removeItem("role");

      this.$router.push("/login");
    },
  },
};
</script>

<style>
.bg-custom-1 {
  background-color: #85144b;
}

.bg-custom-2 {
  background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
}
.admin-brand-img,
.profile-image {
  height: 40px !important;
}
.user_menu {
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}
/* nav a.router-link-exact-active {
    color:black !important;
} */
</style>
