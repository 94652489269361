import { render, staticRenderFns } from "./add-user.vue?vue&type=template&id=2e3b8fc0&"
import script from "./add-user.vue?vue&type=script&lang=js&"
export * from "./add-user.vue?vue&type=script&lang=js&"
import style0 from "@vueform/multiselect/themes/default.css?vue&type=style&index=0&lang=css&"
import style1 from "./add-user.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports