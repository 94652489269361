import axios from "axios";
const state = {
  feeds: [],
  cloneFeeds: [],
};
const getters = {
  feedsList: (state) => state.feeds,
};
const actions = {
  async fetchfeeds({ commit }, data) {
    //console.log('filter',data)
    await axios
      .get(
        `${process.env.VUE_APP_BASEURL}/getFeeds?page=${data.page}&per_page=${data.perPage}&search=${data?.search}&order_by=${data?.order_by}&media_type=${data?.media_type}&ordertype=${data?.ordertype}&used=${data?.used}&type=${data?.type}&observance_start_day=${data?.observance_start_day}&observance_end_day=${data?.observance_end_day}&observance_start_month=${data?.observance_start_month}&observance_end_month=${data?.observance_end_month}`,
        {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        // console.log(response.data)
        commit("setfeeds", response.data);
      })
      .catch((err) => {
        // console.log('er',err)
        // alert(`Something went wrong`)

        if (err.message == "Request failed with status code 401") {
          localStorage.removeItem("token");
          this.$router.push("/login");
        }
        if (err.message == "Request failed with status code 422") {
          localStorage.removeItem("token");
          this.$router.push("/login");
        }

      });
  },
  async addfeeds({ commit }, user) {
    const response = await axios.post("http://localhost:3000/feeds", user);
    commit("addNewUser", response.data);
  },
  async filterfeeds({ commit }, data) {
    //const response = await axios.post("http://localhost:3000/feeds", user);
    // const response = await axios.get("http://localhost:3000/feeds");
    commit("filterFeed", data);
  },
  async deletefeeds({ commit }, id) {
    let obj = {
      feed_id: id
    }
    await axios
      .post(process.env.VUE_APP_BASEURL + `/delete-custom-feed`, obj, {
        headers: {
          "Content-type": "application/json",
          token: `${localStorage.getItem("token")}`,
        },
      })
      .catch((err) => {
        if (err.message == "Request failed with status code 401") {
          localStorage.removeItem("token");
          this.$router.push("/login");
        }
      });
    commit("removeUser", id);
  },
};
const mutations = {
  setfeeds: (state, feeds) => {
    if (feeds) {
      // console.log(feeds)
      (state.feeds = feeds), (state.cloneFeeds = feeds);
    } else {
      (state.feeds = []), (state.cloneFeeds = []);
    }
  },
  addNewUser: (state, user) => (
    state.feeds.unshift(user), state.cloneFeeds.unshift(user)
  ),
  removeUser: (state, id) => (
    state.feeds.filter((user) => user.id !== id),
    state.feeds.splice((user) => user.id, 1),
    state.cloneFeeds.filter((user) => user.id !== id),
    state.cloneFeeds.splice((user) => user.id, 1)
  ),
  filterFeed: (state, data) =>
    // console.log('state',state,data, state.cloneFeeds.filter((u=>u.type==data))),
    (state.feeds = state.cloneFeeds.filter((u) => u.type == data)),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
