<template>
  <div class="col-12 bg-light py-5">
    <div class="container">
      <div class="row">
        <div class="mx-auto mb-5">
          <div class="py-5 text-center">
            <img src="/images/success.png" />
            <h1 class="display-2 fw-bold text-center text-info pt-5 mb-2">
              Payment Success!
            </h1>

            <h3 class="text-bolder text-uppercase"><b>Transaction Id</b></h3>
            <div
              v-if="showTransctionIdLoader"
              class="spinner-border text-info"
              style="
                width: 2rem;
                border-top: 3px solid;
                border-bottom: 3px solid;
                border-left: 3px solid;
                height: 2rem;
              "
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <div v-else>
              <h3 class="fs-4">{{ transId }}</h3>
            </div>

            <h3 class="text-secondary fw-400">
              Now you can go to dashboard and also you can access all pages
            </h3>

            <div class="pt-5">
              <router-link
                @click.native="$scrollToTop"
                class="btn btn-info text-white text-uppercase rounded-pill text-uppercase fs-5 fw-semibold px-5 py-3"
                to="/profile"
                >Go to Account</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Success",
  data() {
    return {
      transId: "",
      showTransctionIdLoader: true,
      campaignMail: "",
      campaignFirstName: "",
      campaignLastName: "",
    };
  },
  created() {
    if (this.$route?.query?.session_id) {
      var obj = {
        payment_gateway: "stripe",
        sessions_key: this.$route?.query?.session_id,
      };
      axios
        .post(process.env.VUE_APP_BASEURL + "/saveTransaction", obj, {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.transId = res?.data?.transaction_details?.id;
          this.campaignMail = res?.data?.user_details?.email;
          this.campaignFirstName = res?.data?.user_details?.first_name;
          this.campaignLastName = res?.data?.user_details?.last_name;
          this.runCampaign();
          this.showTransctionIdLoader = false;
          // console.log(res)
          localStorage.setItem("used", 1);
          localStorage.setItem("pay_status", res?.data?.pay_status);
          this.$router.replace({ query: null });
        })
        .catch((err) => {
          this.showTransctionIdLoader = false;
          // console.log(err);
        });
    } else {
      this.$router.push("/profile");
    }
    // console.log(this.$route?.query?.session_id)
  },
  methods: {
    runCampaign() {
      var obj = {
        email: this.campaignMail,
        first_name: this.campaignFirstName,
        last_name: this.campaignLastName,
      };
      axios
        .post(process.env.VUE_APP_BASEURL + "/runCampaign", obj, {
          headers: {
            "Content-type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {})
        .catch((err) => {});
    },
  },
};
</script>

<style>
.payment {
  border: 1px solid #f2f2f2;
  height: 280px;
  border-radius: 20px;
  background: #fff;
}
.payment_header {
  background: rgba(255, 102, 0, 1);
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
}

.check {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  text-align: center;
}

.check i {
  vertical-align: middle;
  line-height: 50px;
  font-size: 30px;
}

.content {
  text-align: center;
}

.content h1 {
  font-size: 25px;
  padding-top: 25px;
}

.content a {
  width: 200px;
  height: 35px;
  color: #fff;
  border-radius: 30px;
  padding: 5px 10px;
  background: rgba(255, 102, 0, 1);
  transition: all ease-in-out 0.3s;
}

.content a:hover {
  text-decoration: none;
  background: #000;
}
</style>
